import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../../components/layout'
import { RelatedArticles } from '../../components/RelatedArticles'
import SEO from '../../components/seo'
import { parseBlockContent } from '../../components/Blocks/data'
import he from 'he'
import { stripHtml } from 'string-strip-html'

import {
  ComponentContainer,
  ContentStyles,
} from '../../utils/globalStyledComponents'

class PostTemplate extends Component {
  render() {
    let post

    if (this.props.preview) {
      post = this.props.data
    } else {
      post = this.props.data.post
    }

    const Content = parseBlockContent(post.content)
    console.log('post.excerpt:', stripHtml(post.excerpt)?.result)

    return (
      <Layout
        generic
        user={[]}
        date={post.date}
        primaryCat={post.primaryCat}
        categories={post.categories.nodes}
        headerImage={post.avContent.featureImage}
        headerTitle={post.title}
        breadcrumb={{ name: 'stories', slug: '/stories' }}
      >
        <SEO
          image={post.avContent.featureImage?.sourceUrl}
          title={post.title}
          description={
            post.seo.metaDesc ?? he.decode(stripHtml(post?.excerpt ?? "").result)
          }
        />

        <ComponentContainer>
          <ContentStyles isStories>{Content}</ContentStyles>
        </ComponentContainer>
        <RelatedArticles />
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    post: wpPost(id: { eq: $id }) {
      title
      content
      excerpt
      date
      seo {
        title
        metaDesc
      }
      primaryCat {
        node {
          name
          slug
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }

      avContent {
        featureImage {
          sourceUrl
          srcSet
        }
      }
    }
  }
`
